<template>
  <v-container v-if="loading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3">
    <v-card class="py-3">
      <div class="d-flex align-center justify-space-between px-3 mb-8">
        <div class="font-12px text-888">
          <span>{{ currentDate.format("YYYY-MM-DD") }}</span>
          <p class="mb-0">日報作成率 {{ averageSalePercentage }}％</p>
        </div>
        <v-btn
          text
          :to="{
            name: 'drInput',
            params: { date: currentDate.format('YYYY-MM-DD') }
          }"
          class="font-weight-400 font-14px px-0 dr-edit-btn"
        >
          <v-icon small class="mr-2 icon-opacity">
            $edit
          </v-icon>
          本日の日報を編集する
        </v-btn>
      </div>

      <v-row justify="center" class="mt-3">
        <v-col cols="6" class="py-0">
          <v-select
            solo
            dense
            v-model="yearModel"
            :items="yearsList"
            background-color="#EAEDF0"
            class="dr-select"
            @change="updateYear"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-slide-group
        class="slider-group mx-auto mt-1"
        show-arrows
        v-model="monthModel"
        mobile-breakpoint="0"
      >
        <v-slide-item
          v-for="(item, index) in monthList"
          :key="index"
          v-slot="{ active, toggle }"
          :value="item"
        >
          <v-btn
            active-class="font-weight-bold"
            :input-value="active"
            small
            text
            @click="toggle"
            plain
            :ripple="false"
            :disabled="loading"
            v-on:click="updateMonth(item)"
          >
            {{ item }}月
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <table class="dr-table font-12px mt-5">
        <tr class="fw-700 text-7d">
          <th></th>
          <th>売上</th>
          <th>客数</th>
          <th>天気</th>
          <th></th>
        </tr>
        <tr
          v-for="(row, i) in getMonthDailyReportsList"
          :key="i"
          :class="false ? 'text-red' : ''"
        >
          <!-- data is added for the daily report row -->
          <!-- if it is a business day or a holiday -->
          <template v-if="!row.is_editable && row.business_or_holiday === '1'">
            <td>{{ row.report_date | formatDate("MM/DD dd") }}</td>
            <td>
              <span class="fw-500">{{ row.sales | formatMoney }}</span>
            </td>
            <td>{{ row.customers }}</td>

            <td class="text-9f d-flex justify-center">
              <div
                class=""
                v-for="(icon, index) in getDailyReportWeatherIcons(row.weather)"
                :key="index"
              >
                <v-icon size="15" class="mr-1" color="#9F9F9F">
                  {{ icon }}
                </v-icon>
                <span v-if="index == 0">/</span>
              </div>
            </td>

            <td>
              <v-icon
                size="15"
                @click="
                  $router.push({
                    name: 'drView',
                    params: {
                      id: row.report_date
                    }
                  })
                "
                role="button"
                >$rightArrow</v-icon
              >
            </td>
          </template>
          <!-- if the row for the particular day is empty -->
          <template
            v-else-if="!row.is_editable && row.business_or_holiday === '2'"
          >
            <td class="text-red">
              {{ row.report_date | formatDate("MM/DD dd") }}
            </td>
            <td class="text-red">
              <span class="fw-400">店休日</span>
            </td>
            <td></td>
            <td class="text-9f"></td>
            <td></td>
          </template>
          <template v-else>
            <td>{{ row.report_date | formatDate("MM/DD dd") }}</td>
            <td>
              <span class="fw-500">￥---</span>
            </td>
            <td>---</td>

            <td class="text-9f">
              <div>
                ---
              </div>
            </td>

            <td>
              <v-icon
                size="15"
                class="icon-opacity"
                role="button"
                @click="
                  $router.push({
                    name: 'drInput',
                    params: {
                      date: row.report_date
                    }
                  })
                "
                >$edit</v-icon
              >
            </td>
          </template>
        </tr>
      </table>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "DrList",
  data() {
    return {
      yearModel: null,
      loading: false,
      monthModel: null,
      monthList: [],
      yearsList: [],
      searchResult: [],
      shopId: null,
      currentDate: dayjs()
    };
  },
  created() {
    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.setAllPageData();
    });
    this.setAllPageData();
  },
  computed: {
    getShop() {
      return this.$store.getters.getFirstShopObject;
    },
    getMonthDailyReportsList() {
      return this.$store.getters.getMonthDailyReports?.daily_reports;
    },
    averageSalePercentage() {
      return this.$store.getters.getMonthDailyReports?.average_sale_percentage;
    }
  },
  methods: {
    setAllPageData() {
      this.shopId = this.getShop.id;
      this.generateYears();
      this.generateMonths();
      this.getReportByMonth();
    },
    async getReportByMonth() {
      this.loading = true;
      await this.$store
        .dispatch("GET_DAILY_REPORT_BY_MONTH", {
          shop_id: this.shopId,
          date: `${this.yearModel}-${this.monthModel}`
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * generate years from the start of the fiscal year to the current year
     */
    generateYears() {
      const currentYear = dayjs().year();
      this.yearsList = [currentYear - 1, currentYear];
      this.yearModel = this.yearsList[1];
    },
    generateMonths() {
      this.monthList = Array.from({ length: 12 }, (val, i) => i + 1);
      this.monthModel = this.currentDate.month() + 1;
    },
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },
    updateMonth(item) {
      this.monthModel = item;
      this.getReportByMonth();
    },
    updateYear() {
      this.generateMonths();
      this.$nextTick(() => {
        this.getReportByMonth();
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
